import React from 'react';
import BoatDetailMobileSubpagesLink from '../components/BoatDetailMobileSubpagesLink/BoatDetailMobileSubpagesLink';
import FeatureFeatures from '../components/FeatureFeatures/FeatureFeatures';
import PropTypes from 'prop-types';

const Features =(props)=>(
    <>
        <BoatDetailMobileSubpagesLink slug={props.slug}>CARACTERÍSTICAS</BoatDetailMobileSubpagesLink>
        <div style={{paddingLeft:'45px', paddingRight:'45px', paddingBottom:'32px'}}>
            <FeatureFeatures features={props.features}></FeatureFeatures>
        </div>
    </>
);

Features.propTypes = {
    slug: PropTypes.string,
    features: PropTypes.object,
}

Features.defaultPrps = {
    slug: '',
    features: {},
}

export default Features;