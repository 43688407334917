import React from 'react';
import PropTypes from 'prop-types';

const Line = ({marginTop, marginBottom, style}) => (
  <div style={{width:'100%', borderBottom:'1px solid rgba(0, 0, 0, 0.35)', marginTop:marginTop, marginBottom: marginBottom, ...style}}>
  </div>);

Line.propTypes = {
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  style: PropTypes.object,
}

Line.defaultProps = {
  marginTop: '0px',
  marginBottom: '0px',
  style: {},
}
      

export default Line;