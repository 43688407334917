import { NumberPicker } from 'boatjump-react-components';
import PropTypes from 'prop-types';
import React from 'react';

const emptyFunction = () => {};

const PriceDiv = ({extra, price, numberPicker, pricedivided, divlineneeded, pricecolorblack, formValues, setFormValues, id}) => {
  const valueHandler=(e, value)=>{
    ////console.log(e);
    const tmpValue = {};
    tmpValue[e]=value;
    const newValues = Object.assign({}, formValues, tmpValue);
    setFormValues(newValues);
  }
  const priceColorClass = pricecolorblack? "BoatDetailExtrasPriceBlack" : "BoatDetailExtrasPrice";
  const divLine = divlineneeded? <div className="BoatDetailExtrasLine"></div> : "";
  const divpricedivided = pricedivided === ""? "" : 
    <span className="BoatDetailExtrasPricedivided">
      {pricedivided}
    </span>;
  if(numberPicker){
    return(
      <div className="BoatDetailExtrasContainer">
        <div className="BoatDetailExtrasContainerExtrasContainer">
          <div className="BoatDetailExtrasContainerExtrasContainerExtra">
            <span className="BoatDetailExtrasExtra">{extra}</span>
            {divpricedivided}
          </div>
          <div><NumberPicker fieldId={id} style={{marginLeft : '21px'}} returnValue={valueHandler} currentValue={valueHandler} ></NumberPicker></div>
          <div style={{marginLeft:'16px'}} className={priceColorClass}>{price}</div>
        </div>
        {divLine}
      </div>
    );
  }
    return(
      <div className="BoatDetailExtrasContainer">
        <div className="BoatDetailExtrasContainerExtrasContainer">
          <div className="BoatDetailExtrasContainerExtrasContainerExtra">
            <span className="BoatDetailExtrasExtra">{extra}</span>
            {divpricedivided}
          </div>
          <div className={priceColorClass}>{price}</div>
        </div>
        {divLine}
      </div>
    );
  
};

PriceDiv.propTypes = {
  extra: PropTypes.string,
  price: PropTypes.string,
  numberPicker: PropTypes.bool,
  pricedivided: PropTypes.string,
  divlineneeded: PropTypes.bool,
  pricecolorblack: PropTypes.bool,
  formValues: PropTypes.object,
  setFormValues: PropTypes.func,
  id: PropTypes.number,
};

PriceDiv.defaultProps = {
  extra: '',
  price: "0",
  numberPicker: false,
  pricedivided: '',
  pricecolorblack: false,
  formValues: {},
  setFormValues: emptyFunction,
  id: 0,
};

export default PriceDiv;
