import React from 'react';
import BoatDetailMobileSubpagesLink from '../components/BoatDetailMobileSubpagesLink/BoatDetailMobileSubpagesLink';
import FeatureDiv from '../components/FeatureDiv/FeatureDiv';
import EquipmentFeatures from '../components/EquipmentFeatures/EquipmentFeatures';
import PropTypes from 'prop-types';

const Equipment =(props)=>(
    <>
        <BoatDetailMobileSubpagesLink slug={props.slug} >EQUIPAMIENTO</BoatDetailMobileSubpagesLink>
        <div style={{paddingLeft:'45px', paddingRight:'45px', paddingBottom:'32px'}}>
            <EquipmentFeatures equipment={props}></EquipmentFeatures>
            {
                props.comodities.length > 0? <div className="EquipmentSubTitle">COMODIDADES</div> : <></>
            }
            {
                props.comodities.map(comodity => {
                return <FeatureDiv feature={comodity} quantity />
                })
            }
            {
                props.navigations.length > 0? <div className="EquipmentSubTitle">NAVEGACIÓN</div> : <></>
            }
            {
                props.navigations.map(navigation => {
                    return <FeatureDiv feature={navigation} quantity />
                })
            }
        </div>
    </>
);

Equipment.propTypes = {
    slug: PropTypes.string,
    equipment: PropTypes.object,
}

Equipment.defaultPrps = {
    slug: '',
    equipment: {},
}

export default Equipment;