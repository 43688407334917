import { awsConnector } from 'boatjump-react-components';
import React from 'react';
import BoatDetailContainerLayout from '../components/BoatDetailLayout/BoatDetailContainerLayout';
import '../components/layout.css';
import '../static/search.css';

const BoatDetail = ({ pageContext }) => {
  const {
    boat, url, lang, slugPartBoatRent, description, provider = {}, slugPartBoatsOf, special,
    slugPartOwner, boatRoute, kind,
  } = pageContext;
  // console.log(pageContext);
  const AWSBoatDetail = awsConnector(BoatDetailContainerLayout, {
    identityPoolId: 'eu-west-1:d89b66a9-ed83-43d4-b4fe-2a836929175c', endopointurl: 'https://api.boatjump.com/v1', endpointname: 'provider', region: 'eu-west-1',
  });
  return (
    <>
      <AWSBoatDetail kind={kind} boatroute={boatRoute} special={special} slugPartBoatsOf={slugPartBoatsOf} slugPartOwner={slugPartOwner} provider={provider} slugPartBoatRent={slugPartBoatRent} lang={lang} boats={boat} url={url} description={description} />
    </>
  );
};

export default BoatDetail;
