import { Link } from '@reach/router';
import React from 'react';

const BoatDetailMobileSubpagesLink = (props) => (
  <>
    <Link to={props.slug}>
      <div id="2" className="OwnerRow OwnerSticky" style={{ height: '35px', borderBottom: '1px solid #ccc', marginBottom:'8px' }}>
        <div className="OwnerRow" style={{ justifyContent: 'flex-start' }}>
          <div className="OwnerRightArrow" style={{ transform: 'rotate(180deg)' }} />
          <div style={{ marginLeft: '8px' }}>{props.children}</div>
        </div>
      </div>
    </Link>
  </>
);

export default BoatDetailMobileSubpagesLink;
