import React from 'react';
import FeatureDiv from '../FeatureDiv/FeatureDiv';
import PropTypes from 'prop-types';

const FeatureFeatures = ({features}) => (
    <>
        {
            features.shipyard? <FeatureDiv feature="Astillero" quantity={`${features.shipyard}`} /> : <></>
        }
        {
            features.model? <FeatureDiv feature="Modelo" quantity={`${features.model}`} /> : <></>
        }
        {
            features.length? <FeatureDiv feature="Eslora" quantity={`${features.length}`} /> : <></>
        }
        {
            features.beam? <FeatureDiv feature="Manga" quantity={`${features.beam}`} /> : <></>
        }
        {
            features.capacity? <FeatureDiv feature="Plazas" quantity={`${features.capacity}`} /> : <></>
        }
        {
            features.build_year? <FeatureDiv feature="Año construcción" quantity={`${features.build_year}`} /> : <></>
        }
        {
            features.cabins? <FeatureDiv feature="Número de cabinas" quantity={`${features.cabins}`} /> : <></>
        }
        {
            features.toilets? <FeatureDiv feature="Número de baños" quantity={`${features.toilets}`} /> : <></>
        }
    </>
);

FeatureFeatures.propTypes = {
    features: PropTypes.object,
}

FeatureFeatures.defaultProps = {
    features: {},
}

export default FeatureFeatures;