import React, { useEffect, useState } from 'react';
import BoatDetailLayout from './BoatDetailLayout';

const BoatDetailContainerLayout = ({
  boatroute, boats, lang, slugPartBoatRent, api, url, description, provider, slugPartBoatsOf, slugPartOwner, special, kind,
}) => {
  const { boat, boatExtras, boatFeatures } = boats;
  const [commentaries, setCommentaries] = useState([]);

  useEffect(() => {
    api.get('provider', `/provider/${boat.user_id}/reviews`, {}).then((response) => {
      setCommentaries(response);
      //console.log(response, 'COMMENTARIES');
    }).catch((error) => {
      setCommentaries([]);
      //console.log(error, 'COMMENTARIES');
    });
  }, []);
  return (
    <>
      <BoatDetailLayout kind={kind} boatroute={boatroute} special={special} slugPartBoatsOf={slugPartBoatsOf} slugPartOwner={slugPartOwner} provider={provider} commentaries={commentaries} slugPartBoatRent={slugPartBoatRent} lang={lang} boat={boat} boatExtras={boatExtras} boatFeatures={boatFeatures} url={url} seoDescription={description} />
    </>
  );
};

export default BoatDetailContainerLayout;
