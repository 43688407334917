import React from 'react';
import FeatureDiv from '../FeatureDiv/FeatureDiv';
import PropTypes from 'prop-types';

const EquipmentFeatures = ({equipment}) => (
    <>
        {
            equipment.horse_power? <FeatureDiv feature="Potencia motor" quantity={`${equipment.horse_power} CV`} /> : <></>
        }
        {
            equipment.water? <FeatureDiv feature="Agua" quantity={`${equipment.water} Litros`} /> : <></>
        }
        {
            equipment.fuel? <FeatureDiv feature="Combustible" quantity={`${equipment.fuel} Litros`} /> : <></>
        }
    </>
);

EquipmentFeatures.propTypes = {
    equipment: PropTypes.object,
}

EquipmentFeatures.defaultProps = {
    equipment: {},
}

export default EquipmentFeatures;