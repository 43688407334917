import React from 'react';
import BoatDetailMobileSubpagesLink from '../components/BoatDetailMobileSubpagesLink/BoatDetailMobileSubpagesLink';
import { StaticContent } from 'boatjump-react-components';
import PropTypes from 'prop-types';

const AboutMyBoat =(props)=>(
    <>
        <BoatDetailMobileSubpagesLink slug={props.slug}>SOBRE MI BARCO</BoatDetailMobileSubpagesLink>
        <div style={{paddingLeft:'45px', paddingRight:'45px'}}>
            <div className="BoatDetailSpecificationsContainerAboutMyBout" style={{paddingBottom:'32px'}}><StaticContent content={props.content}></StaticContent></div>
        </div>
    </>
);

AboutMyBoat.propTypes = {
    slug: PropTypes.string,
    content: PropTypes.string,
}

AboutMyBoat.defaultPrps = {
    slug: '',
    content: '',
}

export default AboutMyBoat;