import React from 'react';
import PriceDiv from '../components/PriceDiv/PriceDiv';
import Line from '../components/Line/Line';
import { Button } from 'antd';
import { Input, withButtonStyle } from 'boatjump-react-components';
import PropTypes from 'prop-types';

const ButtonWithStyle = withButtonStyle(Button);

const emptyFunction = () => {}

const Price =(props)=>{
    props.sethomelink(`${props.slug}`);
    return(
    <>
        <div className="BoatDetailBackgroundRight">
            <div className="BoatDetailBackgroundRightMessageError" style={props.datenotavailable}>
                <div className="BoatDetailBackgroundRightMessageErrorImage" />
                <div className="BoatDetailBackgroundRightMessageErrorText">Oops... parece que no está disponible en esas fechas. Prueba cambiándolas!</div>
            </div>
            <div style={{display:'flex'}}>
                <Input style={{marginRight:'18px'}} label="Salida" placeholder="dd/mm/aaaa" icon="Calendar"></Input>
                <Input label="Duración" placeholder="dd/mm/aaaa" icon="Calendar"></Input>
            </div>
            <div style={props.dateavailable}>
                <Line marginTop={"32px"} marginBottom={"32px"}></Line>
                <Input label="Pasajeros" placeholder="8 pasajeros" icon="Passenger"></Input>
                {
                    props.includedExtras.length > 0? <div className="BoatDetailTitleExtras">Extras incluídos</div> : <></>
                }
                {
                    props.includedExtras.map((extra, i) => 
                        <PriceDiv extra={extra.name.es} price="" 
                        formValues={props.formValues} 
                        setFormValues={props.setFormValues} 
                        pricedivided=""
                        divlineneeded={!(props.includedExtras.length - 1 === i)}
                        ></PriceDiv>
                    )
                }
                {
                    props.includedExtras.length > 0? <Line marginTop={"35px"} marginBottom={"4px"}></Line> : <></>
                }
                {
                    props.mandatoryExtras.length > 0? <div className="BoatDetailTitleExtras">Extras obligatorios</div> : <></>
                }
                {
                    props.mandatoryExtras.map((extra, i) => 
                        <PriceDiv extra={extra.name.es} price={`${props.currency} ${extra.ammount}`} 
                        formValues={props.formValues} 
                        setFormValues={props.setFormValues} 
                        pricedivided={extra.ammount_per_day? `${props.currency} ${extra.ammount} per day` : 
                        extra.ammount_per_person? `${props.currency} ${extra.ammount} per person` : 
                        extra.ammount_per_week? `${props.currency} ${extra.ammount} per week` : ""}
                        divlineneeded={!(props.mandatoryExtras.length - 1 === i)}
                        ></PriceDiv>
                    )
                }
                {
                    props.mandatoryExtras.length > 0? <Line marginTop={"35px"} marginBottom={"4px"}></Line> : <></>
                }
                {
                    props.optionalExtras.length > 0? <div className="BoatDetailTitleExtras">Extras opcionales</div> : <></>
                }
                {
                    props.optionalExtras.map((extra,i) => 
                        <PriceDiv extra={extra.name.es} price={`${props.currency} ${extra.ammount}`} 
                        formValues={props.formValues} 
                        setFormValues={props.setFormValues} 
                        pricedivided={extra.ammount_per_day? `${props.currency} ${extra.ammount} per day` : 
                        extra.ammount_per_person? `${props.currency} ${extra.ammount} per person` : 
                        extra.ammount_per_week? `${props.currency} ${extra.ammount} per week` : ""}
                        numberPicker={true}
                        id={extra.extra_id}
                        divlineneeded={!(props.optionalExtras.length - 1 === i)}
                        ></PriceDiv>
                    )
                }
                {
                    props.optionalExtras.length > 0? <Line></Line> : <></>
                }
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', marginBottom:'32px', marginTop: '51px'}}>
                    <div style={{width: '60%'}}>
                        <PriceDiv extra="Precio alquiler" price="$ 3,780" pricecolorblack={true} formValues={props.formValues} setFormValues={props.setFormValues} ></PriceDiv>
                        <PriceDiv extra="Precio extras" price="$ 1,650" divlineneeded={false} pricecolorblack={true} formValues={props.formValues} setFormValues={props.setFormValues} ></PriceDiv>
                        <div style={{display:'flex', justifyContent: 'space-between'}}>
                            <div className="BoatDetailFinalPriceText">Precio final</div>
                            <div className="BoatDetailFinalPricePrice">$ 5,430</div>
                        </div>
                    </div>
                </div>
                <div className="BoatDetailDisplayNone">
                    <ButtonWithStyle onClick={props.clickbutton}>BLOQUEAR BARCO POR 2 DÍAS</ButtonWithStyle>
                </div>
            </div>
        </div>
    </>
);}

Price.propTypes = {
    sethomelink: PropTypes.func,
    slug: PropTypes.string,
    datenotavailable: PropTypes.object,
    dateavailable: PropTypes.object,
    mandatoryExtras: PropTypes.array,
    optionalExtras: PropTypes.array,
    currency: PropTypes.string,
    formValues: PropTypes.object,
    setFormValues: PropTypes.func,
    clickbutton: PropTypes.func,
}

Price.defaultProps = {
    sethomelink: emptyFunction,
    slug: '',
    datenotavailable: {},
    dateavailable: {display:'none'},
    mandatoryExtras: [],
    optionalExtras: [],
    currency: "€",
    formValues: {},
    setFormValues: emptyFunction,
    clickbutton: emptyFunction,
}

export default Price;