import React from 'react';
import BoatDetailMobileSubpagesLink from '../components/BoatDetailMobileSubpagesLink/BoatDetailMobileSubpagesLink';
import {Commentary} from 'boatjump-react-components';
import PropTypes from 'prop-types';

const Ratings =({commentaries, desktop, slug, lang})=>{
    
    return (
        <>
            {
                desktop? <></> : <BoatDetailMobileSubpagesLink slug={slug}>VALORACIONES</BoatDetailMobileSubpagesLink>
            }
            <div className="BoatDetailRatesContainer">
                {
                    commentaries.map((comment) => {
                        return <Commentary rate={comment.rating} commentary={comment.comment[lang]} style={{marginBottom:'16px'}}></Commentary>
                    })
                }
            </div>
        </>
    );
}

Ratings.propTypes = {
    desktop: PropTypes.bool,
    slug: PropTypes.string,
    commentaries: PropTypes.array,
}

Ratings.defaultProps = {
    desktop: true,
    slug: '',
    commentaries: [{}, {}, {}]
}

export default Ratings;