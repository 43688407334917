import React from 'react';
import PropTypes from 'prop-types';

const FeatureDiv = ({feature, quantity}) => {
  let quantityDiv = <div className="BoatDetailSpecificationsContainerFeatureContainerFeatureContainerQuantity">{quantity}</div>;
  if(typeof quantity === 'boolean'){
    if(quantity){
      quantityDiv = <div className="BoatDetailSpecificationsContainerFeatureContainerFeatureContainerTrueIcon"></div>;
    }else{
      quantityDiv = <div className="BoatDetailSpecificationsContainerFeatureContainerFeatureContainerFalseIcon"></div>;
    }
  }
  return(
    <div className="BoatDetailSpecificationsContainerFeatureContainer">
      <div className="BoatDetailSpecificationsContainerFeatureContainerFeatureContainer">
        <div className="BoatDetailSpecificationsContainerFeatureContainerFeatureContainerFeature">{feature}</div>
        {quantityDiv}
      </div>
      <div className="BoatDetailSpecificationsContainerFeatureContainerLineContainer">
        <div className="BoatDetailSpecificationsContainerFeatureContainerLineContainerLine"></div>
      </div>
    </div>
  );
}

FeatureDiv.propTypes = {
  feature: PropTypes.object,
  quantity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
}

FeatureDiv.defaultProps = {
  feature: {},
  quantity: 0,
}

  export default FeatureDiv;