import { Link, Location, Router } from '@reach/router';
import { Button, Carousel, Rate } from 'antd';
import { Badge, StaticContent, UserBadge, withButtonStyle, withRatingStyle } from 'boatjump-react-components';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import Gallery from 'react-grid-gallery';
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../../static/boatDetail.css';
import AboutMyBoat from '../../subpages/AboutMyBoat';
import Equipment from '../../subpages/Equipment';
import Features from '../../subpages/Features';
import Price from '../../subpages/Price';
import Ratings from '../../subpages/Ratings';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import EquipmentFeatures from '../EquipmentFeatures/EquipmentFeatures';
import FeatureDiv from '../FeatureDiv/FeatureDiv';
import FeatureFeatures from '../FeatureFeatures/FeatureFeatures';
import Header from '../Header/Header';
import '../layout.css';
import Line from '../Line/Line';
import SEO from '../seo';

const ButtonWithStyle = withButtonStyle(Button, {});
const ButtonWithSecondaryStyle = withButtonStyle(Button, {});
const RatingWithStyle = withRatingStyle(Rate);

const paymentButtonClick = () => {
};

const FadeTransitionRouter = (props) => (
  <Location>
    {({ location }) => (
      <TransitionGroup className="transition-group">
        <CSSTransition key={location.key} classNames="fade" timeout={500}>
          <Router location={location} className="router">
            {props.children}
          </Router>
        </CSSTransition>
      </TransitionGroup>
    )}
  </Location>
);

const BoatDetailLayout = ({
  boatroute, special, lang, slugPartBoatRent, commentaries, boat, boatExtras, boatFeatures, seoDescription, url, provider, slugPartBoatsOf, slugPartOwner, kind,
}) => {
  const [t, i18n] = useTranslation();
  let imageDivs =  [];
  if(typeof document != 'undefined'){
    imageDivs = document.getElementsByClassName('ReactGridGallery_tile-viewport');
  }

  if (boat.boat_assets_image_video) {
    boat.boat_assets_image_video.slice(0, 3).map((image, i) => {
      if (imageDivs.length > i) {
        imageDivs[i].style.background = `linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)) 0% 0% / cover, url(http://assets.boatjump.com/uploads/boat/${boat.id}/photos/${image.url}) center center no-repeat`;
        imageDivs[i].style.backgroundSize = '100%';
        imageDivs[i].style.borderStyle = 'solid';
        imageDivs[i].style.borderColor = '#000000';
        imageDivs[i].style.borderWidth = '1px 0px 1px 1px';
        imageDivs[i].style.cursor = 'pointer';
      }
    });
  }

  const galleryImagesToShowWithoutModal = (boat.boat_assets_image_video) ? boat.boat_assets_image_video.slice(0, 3).map((image) => ({
    src: `http://assets.boatjump.com/uploads/boat/${boat.id}/photos/${image.url}`,
    thumbnail: `http://assets.boatjump.com/uploads/boat/${boat.id}/photos/${image.url}`,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  })) : [];

  const galleryImagesToShowWithModal = (boat.boat_assets_image_video) ? boat.boat_assets_image_video.map((image) => ({
    src: `http://assets.boatjump.com/uploads/boat/${boat.id}/photos/${image.url}`,
    thumbnail: `http://assets.boatjump.com/uploads/boat/${boat.id}/photos/${image.url}`,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  })) : [];
  const comodities = [];
  const navigations = [];
  boat.boat_features = (!boat.boat_features) ? [] : boat.boat_features;
  // boat.boat_features.map((feature) => {
  //   if (feature.kind === 'commodity') { comodities.push(feature.name.es); } else { navigations.push(feature.name.es); }
  // });

  const place = boatroute.filter((route) => route.id === boat.main_place_id);
  const equipment = { horse_power: boat.horse_power, water: boat.water, fuel: boat.fuel };
  const features = {
    shipyard: boat.ship_yard, model: boat.model, length: boat.length, capacity: boat.capacity, beam: boat.beam, build_year: boat.build_year, cabins: boat.cabins, toilets: boat.toilets,
  };
  const description = boat && boat.boat_description ? boat.boat_description.es : '';
  const carousel = useRef(null);
  const mobileCarousel = useRef(null);
  const rightSide = useRef(null);
  const gallery = useRef(null);
  const [showPaymentButton, setShowPaymentButton] = useState(false);
  const [leftSideDisplayForIpad, setLeftSideDisplayForIpad] = useState('');
  const [rightSideDisplayForIpad, setrightSideDisplayForIpad] = useState('BoatDetailDisplayNone');
  const [carouselImgs, setCarouselImgs] = useState([]);
  const [headerBackground, setHeaderBackground] = useState('');
  const [stickyDivStyle, setStickyDivStyle] = useState({});
  const [dateNotAvailableErrorDiv, setDateNotAvailableErrorDiv] = useState({ display: 'none' });
  const [dateNotAvailableDetailsDiv, setDateNotAvailableDetailsDiv] = useState({});
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [formValues, setFormValues] = useState({});
  const [homeIconLink, setHomeIconLink] = useState('/');
  const [galleryImagesToShow, setGalleryImagesToShow] = useState(galleryImagesToShowWithoutModal);
  const [currentPage, setCurrentPage] = useState('main');

  const groupFiltersFromBoatAndBoatExtras = (arrayOfExtrasFromBoat, arrayOfExtrasFromBoatExtras) => {
    const groupedExtras = arrayOfExtrasFromBoat.map((extra) => {
      const extraThatMatches = arrayOfExtrasFromBoatExtras.filter(((extraFromBoatExtras) => extraFromBoatExtras.id === extra.extra_id));
      if (extraThatMatches[0]) {
        return {
          ...extra, category: extraThatMatches[0].category, description: extraThatMatches[0].description ? extraThatMatches[0].description : '', id: extraThatMatches[0].id, name: extraThatMatches[0].name, type: extraThatMatches[0].type,
        };
      }
      return {};
    });
    return groupedExtras;
  };
  const { boat_extras } = boat;
  const boatExtrasGrouped = groupFiltersFromBoatAndBoatExtras(boat_extras, boatExtras);

  const filterExtrasByTypeAndDate = (type) => (extra) => extra.type === type
          && moment().diff(moment(extra.valid_from)) >= 0
          && moment().diff(extra.valid_until) <= 0;

  const optionalExtras = boatExtrasGrouped.filter(filterExtrasByTypeAndDate('optional'));
  const mandatoryExtras = boatExtrasGrouped.filter(filterExtrasByTypeAndDate('mandatory'));
  const includedExtras = boatExtrasGrouped.filter(filterExtrasByTypeAndDate('included'));

  const lightboxWillOpenFunc = () => {
    setGalleryImagesToShow(galleryImagesToShowWithModal);
  };

  const lightboxWillCloseFunc = () => {
    setGalleryImagesToShow(galleryImagesToShowWithoutModal);
  };

  const changeToPaymentButton = () => {
    if ((window.location.href).endsWith('payment')) {
      setShowPaymentButton(true);
      setLeftSideDisplayForIpad('BoatDetailDisplayNone');
      setrightSideDisplayForIpad('');
    } else {
      setShowPaymentButton(false);
      setLeftSideDisplayForIpad('');
      setrightSideDisplayForIpad('BoatDetailDisplayNone');
    }
  };
  const onClickCalculatePrice = () => {
    setShowPaymentButton(true);
    setLeftSideDisplayForIpad('BoatDetailDisplayNone');
    setrightSideDisplayForIpad('');
  };

  const PaymentButton = ({}) => {
    if (showPaymentButton) {
      return (
        <ButtonWithStyle onClick={paymentButtonClick}>BLOQUEAR BARCO</ButtonWithStyle>
      );
    }
    return (
      <div onClick={()=>{setCurrentPage('payment')}} to={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}/payment/`} onClick={onClickCalculatePrice}><ButtonWithSecondaryStyle mode="Secondary">CALCULAR PRECIO</ButtonWithSecondaryStyle></div>
    );
  };

  const showPhotosClick = (event) => {
    gallery.current.openLightbox(0, event);
  };

  const showPhotosMobile = (event) => {
    gallery.current.openLightbox(mobileCarousel.current.innerSlider.state.currentSlide, event);
  };

  const handleScroll = (event) => {
    event.preventDefault();
    const { scrollTop } = event.target.scrollingElement;
    const headerHeigth = 64;
    const { offsetHeight: carouselHeigth } = carousel.current ? carousel.current : { offsetHeight: 0 };
    const { offsetHeight: rightSideBackgroundHeight } = rightSide.current ? rightSide.current : { offsetHeight: 0 };

    if (scrollTop > (carouselHeigth - headerHeigth)) {
      setHeaderBackground('HeaderContainerBoatDetailScrollMobile');
    } else {
      setHeaderBackground('');
    }

    if (scrollTop >= rightSideBackgroundHeight - carouselHeigth) {
      const stickyDivStyleTop = window.innerHeight > rightSideBackgroundHeight ? 88 : window.innerHeight - rightSideBackgroundHeight;
      setStickyDivStyle({ position: 'sticky', top: `${stickyDivStyleTop}px` });
    } else {
      setStickyDivStyle({});
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowWidth(window.screen.width);
      setWindowHeight(window.screen.height);

      window.addEventListener('popstate', (event) => {
        changeToPaymentButton();
      });

      window.addEventListener('scroll', handleScroll);

      window.onresize = (e) => {
        setWindowWidth(window.screen.width);
      };
    }

    changeToPaymentButton();

    const tmpArray = (boat.boat_assets_image_video) ? boat.boat_assets_image_video.map((el) => renderToString(<div
      className="BoatDetailImageHeigth"
      key={el}
      style={{
        background: `url("http://assets.boatjump.com/uploads/boat/${boat.id}/photos/${el.url}")no-repeat center`,
        backgroundSize: 'cover',
      }}
    />)) : [];
    setCarouselImgs(tmpArray);
  }, [dateNotAvailableErrorDiv, dateNotAvailableDetailsDiv, formValues, homeIconLink]);

  const desktopLayout = (
    <div className="BoatDetailContainer">
      <div className="BoatDetailCarouselContainer" ref={carousel}>
        <Gallery id="fu" style={{ width: '100%' }} backdropClosesModal showImageCount={false} lightboxWillClose={lightboxWillCloseFunc} lightboxWillOpen={lightboxWillOpenFunc} images={galleryImagesToShow} ref={gallery} />
        <div className="BoatDetailSeePhotosContainer">
          <Button
            onClick={showPhotosClick}
            style={{
              width: '140px', height: '48px', backgroundColor: '#FFFFFF', borderRadius: '8px',
            }}
          >
VER FOTOS

          </Button>
        </div>
      </div>
      <div className="BoatDetailGlobalBackground">
        <div className={`BoatDetailLeftSide ${leftSideDisplayForIpad}`}>
          <div className="BoatDetailBackground">
            <div className="BoatDetailInfoContainer">
              <div className="BoatDetailInfoContainerOrientation">
                <div className="BoatDetailInfoContainerInfo">
                  <Breadcrumb breadcrumb={[{ url: '/', text: 'Home' },
                    { url: `/${lang}/${slugPartBoatRent[lang]}/${place[0].slug[lang]}`, text: place[0].name[lang] },
                    { url: `/${lang}/${slugPartBoatRent[lang]}/${place[0].slug[lang]}?boat=${kind.slug[lang]}`, text: kind.name[lang] },
                    { url: '/', text: `${boat.model}` },
                  ]}
                  >
                  </Breadcrumb>
                  <h1 className="BoatDetailInfoContainerInfoTitle">{boat.model}</h1>
                  <div className="BoatDetailInfoContainerInfoPlace">
                    {
                    boatroute.map((route) => (
                      <span>
                        {' '}
                        {route.name[lang]}
                        {' '}
                        ·
                      </span>
                    ))
                  }

                  </div>
                </div>
                <div className="BoatDetailInfoContainerUserInfo">
                  <div className="BoatDetailUserBadgeMarginRightIpad">
                    <Link to={`/${lang}/${slugPartOwner[lang]}/${slugPartBoatsOf[lang]}${provider.provider.name}-${provider.provider.id}/`}>
                      <UserBadge username={provider.provider.name} reviews={provider.reviews_count} />
                    </Link>
                  </div>
                  <div className="BoatDetailRatingMarginRightDesktop"><RatingWithStyle disabled value={boat.average_rating} /></div>
                </div>
              </div>
              <div className="BoatDetailInfoContainerDetails">
                <div className="BoatDetailInfoContainerDetailsInfo">
                  {boat.capacity}
                  {' '}
pasajero
                  {boat.capacity !== 0 ? 's' : ''}
                  {' '}
·
                  {' '}
                  {boat.length}
                  {' '}
m de eslora ·
                  {' '}
                  {boat.toilets}
                  {' '}
baño
                  {boat.toilets !== 0 ? 's' : ''}
                  {' '}
· Año
                  {' '}
                  {boat.build_year}
                  {' '}
·
                  {' '}
                  {boat.cabins}
                  {' '}
cabina
                  {boat.cabins !== 0 ? 's' : ''}
                  {' '}
· Patrón
                  {' '}
                  {boat.skipper}
                </div>
                <div className="BoatDetailBadgeWidth">
                  <Badge className="BoatDetailInfoContainerDetailsBadge" size="Large" mode="NotAvailable">No disponible</Badge>
                </div>
              </div>
            </div>
            <div className="BoatDetailLineContainer">
              <div className="BoatDetailLineContainerLine" />
            </div>
            <div className="BoatDetailSpecificationsContainer">
              <div className="BoatDetailSpecificationsContainerTitleInformation">SOBRE MI BARCO</div>
              <div className="BoatDetailSpecificationsContainerAboutMyBout"><StaticContent content={description} /></div>
              <div className="BoatDetailSpecificationsContainerTitleInformation">EQUIPAMIENTO</div>
              <EquipmentFeatures equipment={equipment} />
              {
                comodities.length > 0 ? <div className="BoatDetailSpecificationsContainerTitleInformation">COMODIDADES</div> : <></>
              }
              {
                comodities.map((comodity) => <FeatureDiv feature={comodity} quantity />)
              }
              {
                navigations.length > 0 ? <div className="BoatDetailSpecificationsContainerTitleInformation">Navegación</div> : <></>
              }
              {
                navigations.map((navigation) => <FeatureDiv feature={navigation} quantity />)
              }
              <div className="BoatDetailSpecificationsContainerTitleInformation">CARACTERÍSTICAS</div>
              <FeatureFeatures features={features} />
            </div>
          </div>
          <div className="BoatDetailBackground BoatDetailBackgroundLeftRate">
            <div className="BoatDetailRatesTitle">Valoraciones</div>
            <Ratings lang={lang} commentaries={commentaries} />
          </div>
        </div>
        <div style={stickyDivStyle} className={`BoatDetailRightSide ${rightSideDisplayForIpad}`} ref={rightSide}>
          <Price
            path={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}/payment`}
            slug={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}`}
            datenotavailable={dateNotAvailableErrorDiv}
            dateavailable={dateNotAvailableDetailsDiv}
            clickbutton={paymentButtonClick}
            formValues={formValues}
            setFormValues={setFormValues}
            mandatoryExtras={mandatoryExtras}
            optionalExtras={optionalExtras}
            includedExtras={includedExtras}
            currency={boat.currency}
          />
        </div>
      </div>
      <div className="BoatDetailPriceFooter">
        <Line marginTop="0px" marginBottom="0px" />
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }}>
              <div className="BoatDetailPriceTextMobile">PRECIO</div>
              <div className="BoatDetailPricePriceMobile">$ 5,430</div>
            </div>
            <div className="BoatDetailPriceExtrasMobile">Con extras obligatorios</div>
          </div>
          <div style={{ width: '160px', marginTop: '8px', marginBottom: '8px' }}>
            <PaymentButton />
          </div>
        </div>
      </div>
    </div>
  );

  const MobileNav = () => {
    setHomeIconLink('/');
    setShowPaymentButton(false);
    return (
      <>
        <div
          onClick={()=>{setCurrentPage('about')}}
          to={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}/about/`}
          style={{
            display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: '17px',
          }}
        >
          <div className="OwnerRightArrow" style={{ height: '25px', margin: '16px 4px 25px 0px' }} />
          <div className="BoatDetailSpecificationsContainerTitleInformation" style={{ height: '25px' }}>SOBRE MI BARCO</div>
        </div>
        <Line marginTop="-13px" marginBottom="-4px" style={{ width: '90%', marginLeft: '17px' }} />
        <div
          onClick={()=>{setCurrentPage('features')}}
          to={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}/features/`}
          style={{
            display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: '17px',
          }}
        >
          <div className="OwnerRightArrow" style={{ height: '25px', margin: '16px 4px 25px 0px' }} />
          <div className="BoatDetailSpecificationsContainerTitleInformation" style={{ height: '25px' }}>CARACTERÍSTICAS</div>
        </div>
        <Line marginTop="-13px" marginBottom="-4px" style={{ width: '90%', marginLeft: '17px' }} />
        <div
          onClick={()=>{setCurrentPage('equipment')}}
          to={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}/equipment/`}
          style={{
            display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: '17px',
          }}
        >
          <div className="OwnerRightArrow" style={{ height: '25px', margin: '16px 4px 25px 0px' }} />
          <div className="BoatDetailSpecificationsContainerTitleInformation" style={{ height: '25px' }}>EQUIPAMIENTO</div>
        </div>
        <Line marginTop="-13px" marginBottom="-4px" style={{ width: '90%', marginLeft: '17px' }} />
        <div
          onClick={()=>{setCurrentPage('ratings')}}
          to={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}/ratings/`}
          style={{
            display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: '17px',
          }}
        >
          <div className="OwnerRightArrow" style={{ height: '25px', margin: '16px 4px 25px 0px' }} />
          <div className="BoatDetailSpecificationsContainerTitleInformation" style={{ height: '25px' }}>VALORACIONES</div>
        </div>
      </>
    );
  };

  const mobileLayout = (
    <div className="BoatDetailContainer">
      <div style={{ width: '0%', height: '0%' }}>
        <Gallery id="fu" backdropClosesModal showImageCount={false} lightboxWillClose={lightboxWillCloseFunc} lightboxWillOpen={lightboxWillOpenFunc} images={galleryImagesToShow} ref={gallery} />
      </div>
      <div className="BoatDetailCarouselContainer" onClick={showPhotosMobile} ref={carousel}>
        <Carousel effect="fade" dots="false" autoplay ref={mobileCarousel}>
          {
            carouselImgs.map((el) => <div key={el} dangerouslySetInnerHTML={{ __html: el }} />)
          }
        </Carousel>
        <div className="BoatDetailBadgeContainer">
          <Badge mode="NotAvailable" size="Normal">No disponible</Badge>
          <a href="" className="YoutubeIconContainer" />
        </div>
      </div>
      <div className="BoatDetailGlobalBackground">
        <div className="BoatDetailLeftSide">
          <div className="BoatDetailBackground">
            <div className="BoatDetailInfoContainer">
              <div className="BoatDetailInfoContainerOrientation">
                <div className="BoatDetailInfoContainerInfo">
                  <Breadcrumb breadcrumb={[{ url: '/', text: 'Home' },
                    { url: `/${lang}/${slugPartBoatRent[lang]}/${place[0].slug[lang]}`, text: place[0].name[lang] },
                    { url: `/${lang}/${slugPartBoatRent[lang]}/${place[0].slug[lang]}?boat=${kind.slug[lang]}`, text: kind.name[lang] },
                    { url: '/', text: `${boat.model}` },
                  ]}
                  >
                  </Breadcrumb>
                  <h1 className="BoatDetailInfoContainerInfoTitle">{boat.model}</h1>
                  <div className="BoatDetailInfoContainerInfoPlace">
                    {
                    boatroute.map((route, i) => (
                      <span>
                        {' '}
                        {route.name[lang]}
                        {' '}
                        {((boatroute.length - 1) > i) ? '·' : ''}
                      </span>
                    ))
                  }
                  </div>
                </div>
                <div className="BoatDetailInfoContainerUserInfo">
                  <div>
                    <Link to={`/${lang}/${slugPartOwner[lang]}/${slugPartBoatsOf[lang]}${provider.provider.name}-${provider.provider.id}/`}>
                      <UserBadge username={provider.provider.name} reviews={provider.reviews_count} />
                    </Link>
                  </div>
                  {/* <div><RatingWithStyle disabled value={boat.average_rating} /></div> */}
                </div>
              </div>
              <div className="BoatDetailInfoContainerDetails">
                <div className="BoatDetailInfoContainerDetailsInfo">
                  {boat.capacity}
                  {' '}
pasajero
                  {boat.capacity !== 0 ? 's' : ''}
                  {' '}
·
                  {' '}
                  {boat.length}
                  {' '}
m de eslora ·
                  {' '}
                  {boat.toilets}
                  {' '}
baño
                  {boat.toilets !== 0 ? 's' : ''}
                  {' '}
· Año
                  {' '}
                  {boat.build_year}
                  {' '}
·
                  {' '}
                  {boat.cabins}
                  {' '}
cabina
                  {boat.cabins !== 0 ? 's' : ''}
                  {' '}
· Patrón
                  {' '}
                  {boat.skipper}
                </div>
                <div className="BoatDetailBadgeWidth">
                  <Badge className="BoatDetailInfoContainerDetailsBadge" size="Large" mode="NotAvailable">No disponible</Badge>
                </div>
              </div>
            </div>
            <div className="BoatDetailLineContainer">
              <div className="BoatDetailLineContainerLine" />
            </div>
            <div>
              <div>
                {
                  (currentPage === 'main') ? <MobileNav path={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}/`} /> :
                  (currentPage === 'about') ? <AboutMyBoat path={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}/about/`} slug={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}/`} content={description} /> :
                  (currentPage === 'features') ? <Features path={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}/features/`} slug={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}/`} features={features} /> :
                  (currentPage === 'equipment') ? <Equipment path={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}/equipment/`} slug={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}/`} equipment={equipment} comodities={comodities} navigations={navigations} /> :
                  (currentPage === 'ratings') ? <Ratings lang={lang} commentaries={commentaries} path={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}/ratings/`} slug={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}/`} desktop={false} /> :
                  (currentPage === 'payment') ? <Price
                    path={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}/payment/`}
                    slug={`/${lang}/${slugPartBoatRent[lang]}/${boat.slug[lang]}/${boat.id}/`}
                    datenotavailable={dateNotAvailableErrorDiv}
                    dateavailable={dateNotAvailableDetailsDiv}
                    clickbutton={paymentButtonClick}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    mandatoryExtras={mandatoryExtras}
                    optionalExtras={optionalExtras}
                    currency={boat.currency}
                    includedExtras={includedExtras}
                    sethomelink={setHomeIconLink}
                  /> : ''
              }
              </div>
              <div style={{ height: '53px' }} />
            </div>
          </div>
        </div>
      </div>
      <div className="BoatDetailPriceFooter">
        <Line />
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }}>
              <div className="BoatDetailPriceTextMobile">PRECIO</div>
              <div className="BoatDetailPricePriceMobile">$ 5,430</div>
            </div>
            <div className="BoatDetailPriceExtrasMobile">Con extras obligatorios</div>
          </div>
          <div style={{ width: '160px', marginTop: '8px', marginBottom: '8px' }}>
            <PaymentButton />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <SEO title="Barco" description={seoDescription} url={url} />
      <Header link={homeIconLink} scrollclass={headerBackground} page="boatDetail" special={special} />
      {
        (windowWidth >= 768 && windowHeight > 500) ? desktopLayout : mobileLayout
      }
    </>
  );
};

export default BoatDetailLayout;
